export const certifais = [
  {
    id: '0',
    name: 'Introduction to Python',
    credentialId: '7c469a0b202c6f2500cfe18c7ab3e6cdcfb4273a',
    from: 'DataCampDataCamp',
    link: 'https://www.datacamp.com/statement-of-accomplishment/course/7c469a0b202c6f2500cfe18c7ab3e6cdcfb4273a',
  },
  {
    id: '2',
    name: 'JavaScript Certificate',
    credentialId: '5dbf09572da2',
    from: 'HackerRank',
    link: 'https://www.hackerrank.com/certificates/5dbf09572da2',
  },
  {
    id: '3',
    name: 'React Certificate',
    credentialId: 'c53edf524e15',
    from: 'HackerRank',
    link: 'https://www.hackerrank.com/certificates/c53edf524e15',
  },
  {
    id: '4',
    name: 'Problem Solving Certif...',
    credentialId: '24a5354c11a5',
    from: 'HackerRank',
    link: 'https://www.hackerrank.com/certificates/24a5354c11a5',
  },
  {
    id: '5',
    // name: 'Modern JavaScript for React',
    name: 'Modern JavaScript for Rea...',
    credentialId: 'UC-e3bcf89d-1934-4df6-aa37-01f01b1bc09b',
    from: 'Udemy',
    link: 'https://www.udemy.com/certificate/UC-e3bcf89d-1934-4df6-aa37-01f01b1bc09b',
  },
  {
    id: '6',
    name: 'The Complete ReactJs Course ...',
    credentialId: 'UC-0425422c-afe7-4e0f-ba35-18b61d441118',
    from: 'Udemy',
    link: 'https://www.udemy.com/certificate/UC-0425422c-afe7-4e0f-ba35-18b61d441118',
  },
];
