/* eslint-disable jsx-a11y/alt-text */
import { certifais } from './data';
import { Fragment, useRef, } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-element-bundle.css';
import 'swiper/swiper-element.min.css';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper-element-bundle.min.css';
import 'swiper/swiper.min.css';

import 'swiper/swiper.css';
import 'swiper/swiper-element.css';

import 'swiper/modules/pagination/pagination.min.css';

// import required modules
import { EffectCoverflow, Pagination } from "swiper";


import DecoderText from 'components/DecoderText';
import Divider from 'components/Divider';

import Section from 'components/Section';

import { reflow } from 'utils/transition';

import Heading from 'components/Heading';

import './certifi.css';
import Text from 'components/Text';

const Header = ({ status, titleId }) => (
  <>
    <Heading
      className={classNames(
        'profile__title',
        `profile__title--${status}`,
        'certifi-header'
      )}
      level={3}
      id={titleId}
    >
      <DecoderText
        text="Licenses & certifications"
        start={status !== 'exited'}
        delay={500}
      />
    </Heading>
  </>
);

const Certifications = ({ id, visible, sectionRef }) => {
  const titleId = `${id}-title`;
  const swiperRef = useRef(null);

  return (

    <Section
      className="profile"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >

      <Transition in={visible} timeout={0} onEnter={reflow}>
        {status => (
          <div className='noselect'>
            <Header status={status} titleId={titleId} />
            <div className="certifi-conter">
              <Swiper
                ref={swiperRef}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                loop={true}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
                onIndexChanged={newIndex => console.log("newIndex", newIndex)}
              >
                {certifais.map((certifai, index) => (
                  <SwiperSlide>
                    <div key={index} className="certifi-area">
                      <div className="certifi-img-area">
                        <a href={certifai.link}>
                          <img
                            src={`certifications/${certifai.credentialId}.png`}
                            alt={`hossein's ${certifai.name} `}
                            width={513}
                          />
                        </a>
                      </div>
                      <div className="certifi-title">
                        <div
                          aria-hidden
                          className="project-summary__index"
                          style={{
                            textAlign: 'left',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Divider
                            notchWidth="64px"
                            notchHeight="8px"
                            collapsed={status !== 'entered'}
                            collapseDelay={1000}
                          />
                          <Text>
                            {certifai.name}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
      </Transition>
    </Section>

  );
};

export default Certifications;