/* eslint-disable jsx-a11y/alt-text */
import classNames from 'classnames';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

// Import Swiper React components

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-element-bundle.css';
import 'swiper/swiper-element.min.css';
import 'swiper/swiper.min.css';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper-element-bundle.min.css';
import 'swiper/swiper.min.css';

import 'swiper/swiper-element.css';
import 'swiper/swiper.css';

import 'swiper/modules/pagination/pagination.min.css';

// import required modules

import DecoderText from 'components/DecoderText';

import Section from 'components/Section';

import { reflow } from 'utils/transition';

import Heading from 'components/Heading';

import Text from 'components/Text';
import './style.css';

const Header = ({ status, titleId }) => (
  <>
    <Heading
      className={classNames(
        'profile__title',
        `profile__title--${status}`,
        'certifi-header'
      )}
      level={3}
      id={titleId}
    >
      <DecoderText text="Experience" start={status !== 'exited'} delay={500} />
    </Heading>
  </>
);

const Experience = ({ id, visible, sectionRef }) => {
  const titleId = `${id}-title`;
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <Section
      className="profile"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition in={visible} timeout={0} onEnter={reflow}>
        {status => (
          <div className="noselect">
            <Header status={status} titleId={titleId} />
            <div className="certifi-conter">
              {/* <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="mySwiper"
              >
                {data.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div key={index} className="certifi-area">
                      <div className="certifi-title">
                        <div
                          aria-hidden
                          className="project-summary__index"
                          style={{
                            background: '#fff',
                            textAlign: 'left',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Divider
                            notchWidth="64px"
                            notchHeight="8px"
                            collapsed={status !== 'entered'}
                            collapseDelay={1000}
                          />
                          <br />
                          <Text>name {item.name}</Text>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                <div className="autoplay-progress" slot="container-end">
                  <svg viewBox="0 0 48 48" ref={progressCircle}>
                    <circle cx="24" cy="24" r="20"></circle>
                  </svg>
                  <span ref={progressContent}></span>
                </div>
              </Swiper> */}
              <Text>At this moment not available</Text>
            </div>
          </div>
        )}
      </Transition>
    </Section>
  );
};

export default Experience;
