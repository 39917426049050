import dolphinacademy from 'assets/dolphinacademy.png';
import hoberChatHomeScreen from 'assets/hober-chat-homescreen.png';
import hoberChatLoginpage from 'assets/hober-chat-loginpage.png';
import { default as icms, default as icmsLarge } from 'assets/icms.png';
import iphone11 from 'assets/iphone-11.glb';
import macbookPro from 'assets/macbook-pro.glb';
import marinaHomeScreen from 'assets/marinaHomeScreen.png';
import marinaSideBar from 'assets/marinaSideBar.png';
import sepied from 'assets/sepied.jpg';
import sprTexture from 'assets/spr-lesson-builder-dark.jpg';
import Footer from 'components/Footer';
import { usePrefersReducedMotion, useRouteTransition } from 'hooks';
import Intro from 'pages/Home/Intro';
import Profile from 'pages/Home/Profile';
import ProjectSummary from 'pages/Home/ProjectSummary';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Certifications from './Certifications/Certifications';
import Experience from './experience';
import './index.css';

const disciplines = ['Web', 'Front-End', 'Back-End', 'Mobile App', 'Machine Learning'];

const Home = () => {
  const { status } = useRouteTransition();

  const { hash, state } = useLocation();
  const initHash = useRef(true);
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollIndicatorHidden, setScrollIndicatorHidden] = useState(false);
  const intro = useRef();
  const projectOne = useRef();
  const projectTwo = useRef();
  const projectThree = useRef();
  const projectFour = useRef();
  const projectFive = useRef();
  const certification = useRef();
  const experience = useRef();
  const details = useRef();
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    const revealSections = [
      intro,
      projectOne,
      projectTwo,
      projectThree,
      projectFour,
      projectFive,
      certification,
      experience,
      details,
    ];

    const sectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const section = entry.target;
            observer.unobserve(section);
            if (visibleSections.includes(section)) return;
            setVisibleSections(prevSections => [...prevSections, section]);
          }
        });
      },

      {
        rootMargin: '0px 0px -10% 0px',
      }
    );

    const indicatorObserver = new IntersectionObserver(
      ([entry]) => {
        setScrollIndicatorHidden(!entry.isIntersecting);
      },

      {
        rootMargin: '-100% 0px 0px 0px',
      }
    );

    revealSections.forEach(section => {
      sectionObserver.observe(section.current);
    });

    indicatorObserver.observe(intro.current);

    return () => {
      sectionObserver.disconnect();
      indicatorObserver.disconnect();
    };
  }, [visibleSections]);

  useEffect(() => {
    const hasEntered = status === 'entered';
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let scrollObserver;
    let scrollTimeout;

    const handleHashchange = (hash, scroll) => {
      clearTimeout(scrollTimeout);
      const hashSections = [intro, projectOne, details, certification, experience];
      const hashString = hash.replace('#', '');
      const element = hashSections.filter(item => item.current.id === hashString)[0];
      if (!element) return;
      const behavior = scroll && !prefersReducedMotion ? 'smooth' : 'instant';
      const top = element.current.offsetTop;

      scrollObserver = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;

          if (entry.isIntersecting) {
            scrollTimeout = setTimeout(
              () => {
                element.current.focus();
              },

              prefersReducedMotion ? 0 : 400
            );
            observer.unobserve(entry.target);
          }
        },

        {
          rootMargin: '-20% 0px -20% 0px',
        }
      );

      scrollObserver.observe(element.current);

      if (supportsNativeSmoothScroll) {
        window.scroll({
          top,
          left: 0,
          behavior,
        });
      } else {
        window.scrollTo(0, top);
      }
    };

    if (hash && initHash.current && hasEntered) {
      handleHashchange(hash, false);
      initHash.current = false;
    } else if (!hash && initHash.current && hasEntered) {
      window.scrollTo(0, 0);
      initHash.current = false;
    } else if (hasEntered) {
      handleHashchange(hash, true);
    }

    return () => {
      clearTimeout(scrollTimeout);

      if (scrollObserver) {
        scrollObserver.disconnect();
      }
    };
  }, [hash, state, prefersReducedMotion, status]);

  return (
    <div className="home" itemScope>
      <Helmet>
        <title itemScope> Seyed Hossein Hosseini | Developer </title>
        <meta
          name="description"
          content="Portfolio of Seyed Hossein Hosseini – a Developer working on web with react js  &amp; mobile apps react native with a focus on motion and user experience design."
        />
        <meta
          name="keywords"
          content="HTML, CSS, JavaScript, react , react native , Front-end , developer , web , system, programer"
        />
        <meta name="author" content="Seyed Hossein Hosseini" />
        <link rel="prefetch" href={iphone11} as="fetch" crossorigin="" />
        <link rel="prefetch" href={macbookPro} as="fetch" crossorigin="" />
      </Helmet>
      <Intro
        id="intro"
        sectionRef={intro}
        disciplines={disciplines}
        scrollIndicatorHidden={scrollIndicatorHidden}
      />
      <ProjectSummary
        id="project-1"
        sectionRef={projectOne}
        visible={visibleSections.includes(projectOne.current)}
        index={1}
        title="ICMS"
        description="The largest system of Melli Bank Iran for the bank's branches. 
        We are working on the largest Banking system in Melli Bank branches,
        this system was rewritten by the lates version of react js , material ui and other.
         Many services were handle to used in the system. 
        With use Redux Saga try to manage stateful data was on the system.
        we used Docker stack to dockerize the application for ease deploying,
        and usage jest to writing test the system and puppeteer to e2e test.
        We always tried to create the best user experience for the end
        user to use the system in peace and ease."
        buttonText="Private Project"
        // buttonLink="/projects/smart-sparrow"
        model={{
          type: 'laptop',
          alt: 'Smart Sparrow lesson builder',
          textures: [
            {
              src: sprTexture,
              srcSet: `${icms}800w, ${icmsLarge}1440w`,
              placeholder: icms,
            },
          ],
        }}
      />
      <ProjectSummary
        id="project-2"
        alternate
        sectionRef={projectTwo}
        visible={visibleSections.includes(projectTwo.current)}
        index={2}
        title="Hober Chat Applecation - Mobile"
        description="Free and OpenSource messenger for Mobile and Web, Mobile version is created by React Native. 
        real foucs is on Ui. I love this experience because i use new technology as like as Socket Io ,
        Graphql and more with react js and react native  and i'm so happy for it."
        buttonText="View Details"
        // buttonLink="https://gamestack.hosseinhosseini.net"
        model={{
          type: 'phone',
          alt: 'App login screen react native',
          textures: [
            {
              src: hoberChatLoginpage,
              srcSet: `${hoberChatLoginpage}254w, ${hoberChatLoginpage} 508w`,
              placeholder: hoberChatLoginpage,
            },
            {
              src: hoberChatHomeScreen,
              srcSet: `${hoberChatHomeScreen} 254w, ${hoberChatHomeScreen} 508w`,
              placeholder: hoberChatHomeScreen,
            },
          ],
        }}
      />
      <ProjectSummary
        id="project-3"
        sectionRef={projectThree}
        visible={visibleSections.includes(projectThree.current)}
        index={3}
        title="Sepid - DayBank"
        description={`Designed interface design, with Figma and implemented -Day Bank- software products such as Senior Management Financial Control System that reduced accounting reconciliation and overdue financial claims by 57% and 39%.
        improved paid conversion data rate by 19%, reduced latency by 12%, and increased performance by 32%.`}
        model={{
          type: 'laptop',
          alt: 'React js Dashboard application software',
          textures: [
            {
              src: sepied,
              srcSet: `${sepied}980w, ${sepied}1376w`,
              placeholder: sepied,
            },
          ],
        }}
      />
      <ProjectSummary
        id="project-4"
        alternate
        sectionRef={projectFour}
        visible={visibleSections.includes(projectFour.current)}
        index={4}
        title="Marina Kish - Reservation application"
        description={`Reservation application was a cross-platform application for Android and
        iOS that was written with React Native, in this application various libraries were used
        for design. React Navigation was used to manage stacks (routes).
        Hermes engine was used to optimized app. The google map api was used to design 
        the map to show the hotels and places of interest in
        terms of coordinates `}
        buttonText="View Details"
        // buttonLink="https://gamestack.hosseinhosseini.net"
        model={{
          type: 'phone',
          alt: 'App login screen react native',
          textures: [
            {
              src: marinaHomeScreen,
              srcSet: `${marinaHomeScreen}254w, ${marinaHomeScreen} 508w`,
              placeholder: marinaHomeScreen,
            },
            {
              src: marinaSideBar,
              srcSet: `${marinaSideBar} 254w, ${marinaSideBar} 508w`,
              placeholder: marinaSideBar,
            },
          ],
        }}
      />
      <ProjectSummary
        id="project-5"
        sectionRef={projectFive}
        visible={visibleSections.includes(projectFive.current)}
        index={5}
        title="Dolphin Academy"
        description="By React js and using Material ui, we designed a system for Dolphin Academy admins.
        My first experience was in product deploy on nginx."
        model={{
          type: 'laptop',
          alt: 'React js Dashboard application software',
          textures: [
            {
              src: dolphinacademy,
              srcSet: `${dolphinacademy}980w, ${dolphinacademy}1376w`,
              placeholder: dolphinacademy,
            },
          ],
        }}
      />
      <Certifications
        sectionRef={certification}
        id="certification"
        visible={visibleSections.includes(certification.current)}
      />
      <Experience
        sectionRef={experience}
        id="experience"
        visible={visibleSections.includes(experience.current)}
      />
      <Profile
        sectionRef={details}
        visible={visibleSections.includes(details.current)}
        id="details"
      />
      <Footer />
    </div>
  );
};

export default Home;
