import { ReactComponent as KatakanaProfile } from 'assets/katakana-profile.svg';
import ProfileImgPlaceholder from 'assets/profile-placeholder.jpg';
import ProfileImg from 'assets/profile.jpg';
import classNames from 'classnames';
import { Button } from 'components/Button';
import DecoderText from 'components/DecoderText';
import Divider from 'components/Divider';
import Heading from 'components/Heading';
import Image from 'components/Image';
import Link from 'components/Link';
import Section from 'components/Section';
import Text from 'components/Text';
import { Transition } from 'react-transition-group';
import { media } from 'utils/style';
import { reflow } from 'utils/transition';
import './Profile.css';

const ProfileText = ({ status, titleId }) => (
  <>
    <Heading
      className={classNames('profile__title', `profile__title--${status}`)}
      level={3}
      id={titleId}
    >
      <DecoderText text="Hi there" start={status !== 'exited'} delay={500} />
    </Heading>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      I’m Hossein, currently I working as a Software Developer at
      <Link href="https://sadad.co.ir/en/">
        <strong> Farabi Financial Group </strong>
      </Link>
      . My projects include Generative AI, and Developing applications and website. Being
      comfortable with code allows me to rapidly prototype and validate experiences.
      {/* If you're interested in the tools and software I
      use check out my <Link href="/uses">uses page</Link>. */}{' '}
      I Love to learn new things and I'm always looking for new challenges and share my
      knowledge.
    </Text>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      In my spare time I like to practice Kyokushin Karate, read book and learn
      <strong> ML/AI.</strong> <br />
      {/* <Link href="/projects/volkihar-knight">React 360 Project</Link>.  */}
      I'm want to hearing about new projects, so feel free to drop me a line.
    </Text>
  </>
);

const Profile = ({ id, visible, sectionRef }) => {
  const titleId = `${id}-title`;

  return (
    <Section
      className="profile"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition in={visible} timeout={0} onEnter={reflow}>
        {status => (
          <div className="profile__content">
            <div className="profile__column">
              <ProfileText status={status} titleId={titleId} />
              <Button
                secondary
                className={classNames('profile__button', `profile__button--${status}`)}
                href="mailto:h.hosseini.me@gmail.com"
                icon="send"
              >
                Send me a Email
              </Button>
            </div>
            <div className="profile__column">
              <div className="profile__tag" aria-hidden>
                <Divider
                  notchWidth="64px"
                  notchHeight="8px"
                  collapsed={status !== 'entered'}
                  collapseDelay={1000}
                />
                <div
                  className={classNames(
                    'profile__tag-text',
                    `profile__tag-text--${status}`
                  )}
                >
                  About Me
                </div>
              </div>
              <div className="profile__image-wrapper">
                <Image
                  reveal
                  delay={100}
                  placeholder={ProfileImgPlaceholder}
                  srcSet={`${ProfileImg} 480w, ${ProfileImg} 960w`}
                  sizes={`(max-width: ${media.mobile}px) 100vw, 480px`}
                  alt="little men with big dreams"
                />
                <KatakanaProfile
                  className={classNames('profile__svg', `profile__svg--${status}`)}
                />
              </div>
            </div>
          </div>
        )}
      </Transition>
    </Section>
  );
};

export default Profile;
